import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/internal/operators';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private authenticationService: AuthenticationService, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(state.url, next);
  }

  checkLogin(url: string, route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (this.authenticationService.isLogged) {
      return true;
    }

    return this.authenticationService.checkAuthentication().pipe(
      catchError((err) => {

        this.router.navigated = false;
        this.router.navigate(['/login']);
        return of(false);
      }),
      tap(response => {
        if(response.success) {
          this.router.navigate(['/' + url]);
          return true;
        } else {
          return this.authenticationService.logout().subscribe(() => {
            this.router.navigate(["/login"]);
            return false;
          });
        }
      })
    );
  }
}
