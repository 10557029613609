import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';


@Directive({
  selector: '[appThumbText]'
})
export class ThumbTextDirective implements AfterViewInit{

  private ThumbTextDefault:object = {'ok': 'yes', 'ko': 'no'};

  @Input('appThumbText') public ThumbText:object;

  constructor(
    private el: ElementRef
  ) {}

  ngAfterViewInit()
  {
    this.setThumbText();
  }

  @HostListener('toggleChange')
  setThumbText(){
    setTimeout(() => {
      this.el.nativeElement.getElementsByClassName("mat-slide-toggle-thumb")[0].innerHTML =
      this.el.nativeElement.classList.contains('mat-checked') ? this.ThumbText['ok'] || this.ThumbTextDefault['ok'] : this.ThumbText['ko'] || this.ThumbTextDefault['ko'];
    }, 100);
  }
}
