import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSlideToggleChange,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import {catchError, debounceTime, delay, distinctUntilChanged, map, startWith, switchMap} from "rxjs/operators";
import {BehaviorSubject, merge, of} from "rxjs";
import {UserServices} from "../user.services";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AddUserComponent} from "../add-user/add-user.component";
import {CrawlServices} from "../../crawl/crawl.services";

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit, AfterViewInit
{
  public loadingSubject = new BehaviorSubject<boolean>(true);
  public isLoadingResults$ = this.loadingSubject.asObservable();

  public loadingSubjectEmail = new BehaviorSubject<boolean>(true);
  public isLoadingResultsEmail$ = this.loadingSubjectEmail.asObservable();

  public loadingSubjectLimit = new BehaviorSubject<boolean>(true);
  public isLoadingResultsLimit$ = this.loadingSubjectLimit.asObservable();

  public opened: boolean;
  public hasData = false;
  public hasEmailData = false;
  public progress = {};

  public form_no_error:any = null;
  public form_error:any = false;
  public form_email_response:any = null;

  public formLimitUser: FormGroup;
  public formFiltersEmails: FormGroup;

  public formFilter: FormGroup;

  public resultsLength = 0;
  public resultsEmailLength = 0;

  public dataSourceEmails = new MatTableDataSource();
  public columnTempEmails: Array<any>;
  public columnsToDisplayEmails = {
      email: 'email',
      actions: ''
  };

  public dataSource = new MatTableDataSource();
  public columnTemp: Array<any>;
  public columnsToDisplay = {
      created_time: 'Created Date',
    email: 'User',
      keywords_filename: 'Filename',
      country: 'Country',
      count_kws: 'Count of keywords',
      status: 'Status',
      actions: '',
  };

  public user = null;
  public slideColor = 'accent';
  public count_kws_this_month: number = 0;

  private _interval;

  @ViewChild('sortemail', {static: false}) sortEmail: MatSort;
  @ViewChild('paginatoremail', {static: false}) paginatorEmail: MatPaginator;

  @ViewChild('sort', {static: false}) sort: MatSort;
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;



  constructor(
    private _userServices: UserServices,
    private _crawlServices: CrawlServices,
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this.columnTemp = Object.keys(this.columnsToDisplay);
    this.columnTempEmails = Object.keys(this.columnsToDisplayEmails);

    this.formFilter = this._formBuilder.group({
      filter: null,
      statusFilter: null
    });

    this.formFilter.valueChanges.subscribe(res => {

      this.loadData();
    });

    this.formLimitUser = this._formBuilder.group({
      keywords_per_files: [50000, Validators.required],
      simultaneous_crawlers: [1, Validators.required],
      quotas_per_month: [300000, Validators.required]
    });

    this.formFiltersEmails = this._formBuilder.group({
      email: ['']
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.sortEmail.sortChange.subscribe(() => this.paginatorEmail.pageIndex = 0);

    this.formFiltersEmails.valueChanges.pipe(debounceTime(600), distinctUntilChanged()).subscribe(() => this.loadDataEmail());

    this.loadDataEmail();
    this.loadData();
  }

  private loadDataEmail() {
    merge(this.sortEmail.sortChange, this.paginatorEmail.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          this.loadingSubjectEmail.next(true);

          return this._userServices.getUserEmailList(
            this.paginatorEmail.pageIndex+1,
            this.paginatorEmail.pageSize,
            this.sortEmail.active,
            this.sortEmail.direction,
            this.formFiltersEmails.get('email').value
          );
        }),
        map((data:any) => {
          setTimeout(() => this.loadingSubjectEmail.next(false), 800);
          this.resultsEmailLength = data.total;
          this.hasEmailData = (data.total > 0) ?  true : false;

          return data.items;
        }),
        catchError(() => {
          setTimeout(() => this.loadingSubjectEmail.next(false), 800);

          return of([]);
        })
      )
      .subscribe(data => this.dataSourceEmails.data = data);
  }

  public extractNameFromEmail(user){
    let email = null;
    console.log(user)
    if(user.email)
      email = user.email;
    if(user.user && user.user.email)
      email = user.user.email;
    if(email){
      return email.split("@")[0];
    }
    return "this user"
  }

  private loadData(id = null) {
    let filters = {};

    if(this.formFilter.get('filter').value != null){
      filters['filter'] = this.formFilter.get('filter').value;
    }
    if(this.formFilter.get('statusFilter').value != null){
      console.log(this.formFilter.get('statusFilter').value);
      filters['statusFilter'] = this.formFilter.get('statusFilter').value;
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          this.loadingSubject.next(true);

          return this._userServices.getUserList(
            id,
            this.paginator.pageIndex+1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            filters
          );
        }),
        map((data:any) => {
          setTimeout(() => this.loadingSubject.next(false), 800);
          this.resultsLength = data.total;
          this.hasData = (data.total > 0) ?  true : false;

          return data.items;
        }),
        catchError(() => {
          setTimeout(() => this.loadingSubject.next(false), 800);

          return of([]);
        })
      )
      .subscribe(data => {
        this.dataSource.data = data;

       if(this._interval !== undefined)
        {
          clearInterval(this._interval);
        }

        this._interval = setInterval(() => this.getProgress(), 2000);
      });
  }

  private loadLimit(id)
  {
    this.loadingSubjectLimit.next(true);

    this._userServices.getUserLimit(id).subscribe(data =>
    {
      setTimeout(() => this.loadingSubjectLimit.next(false), 800);

      this.formLimitUser.get('keywords_per_files').setValue('max_kws_by_file' in data ? data.max_kws_by_file : 0);
      this.formLimitUser.get('simultaneous_crawlers').setValue(1);
      this.formLimitUser.get('quotas_per_month').setValue('max_kws_by_month' in data ? data.max_kws_by_month : 0);

      this.user = data;

      if(data.length < 1)
      {
        this.user.id_users = id;
      }

      this.count_kws_this_month = 'count_kws_this_month' in data ? data.count_kws_this_month : 0;
    });
  }

  public getProcess(element){
    if(this.progress[element.id]){
      return this.progress[element.id];
    }
    else {
      return element;
    }
  }

  public pauseReport(id){
    this._crawlServices.pauseReport(id).subscribe();
  }
  public loadUserData(id = null) {
    this.loadData(id);
    this.loadLimit(id);
  }

  public updateAdminStatus(e: MatSlideToggleChange){
    this._userServices.editAdminStatus({ id: this.user.id_users, value: e.checked === false ? 0 : 1 }).subscribe(data => {});
  }

  public updateUserStatus(e: MatSlideToggleChange, id):void {
    this._userServices.editAccountStatus({ id: id, value: e.checked === false ? 0 : 1 }).subscribe((data) => {});
  }

  public add() {
    const dial = this._dialog.open(AddUserComponent, {autoFocus: false});

    dial.afterClosed().subscribe(result => {
      if(result)
      {
        this.loadDataEmail();
      }
    });
  }

  public edit() {
    this._userServices.editUser({
      ...this.formLimitUser.getRawValue(),
      ...{'id': this.user.id_users}
    }).subscribe(
      (data) => {
        this.form_no_error = 'Limit user has been updated';
      },
      (error) => {
        this.form_error = error.error.error;
      }
    );
  }

  public download(data) {
    window.location.assign('/report/download?id=' + data.id);
  }

  public getProgress() {
    const ids = this.dataSource.data.map((x:any) => x.id);

    this._crawlServices.getProgress(ids).subscribe(data => {
      this.progress = data;
    });
  }

  public getFormatNumber(value) {
    return (value).toLocaleString('fr-FR', {minimumFractionDigits: 0});
  }

}
