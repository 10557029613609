import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {MaterialModule} from './core/material.module';

import {AuthenticationService} from './services/authentication.service';

import {ThemeService} from './services/theme.service';
import {AuthGuardService} from './services/auth-guard.service';

import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
import {CrawlModule} from "./components/crawl/crawl.module";
import {UserModule} from "./components/user/user.module";
import {AppServices} from "./services/app.services";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CrawlModule,
    UserModule
  ],
  providers: [
    ThemeService,
    AuthenticationService,
    AuthGuardService,
    AppServices
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
