import { NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../core/material.module';

import {UserServices} from "./user.services";
import {UserRoutingModule} from "./user-routing.module";
import {UserComponent} from "./user.component";
import { ListUserComponent } from './list-user/list-user.component';
import { AddUserComponent } from './add-user/add-user.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UserRoutingModule,
        MaterialModule
    ],
    declarations: [
        UserComponent,
        ListUserComponent,
        AddUserComponent,
    ],
    entryComponents: [
      AddUserComponent,
    ],
    providers: [
      UserServices
    ]
})
export class UserModule { }
