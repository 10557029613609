import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable()
export class UserServices
{
  public userList = '/user/list';
  public userEmailList = '/user/email/list';
  public userLimit = '/user/limit';
  public userUpdateAdminStatus = '/user/edit_admin';
  public userUpdateAccountStatus = '/user/edit_account_status';
  public userAdd = '/user/add';
  public userEdit = '/user/edit';

  constructor(private _http: HttpClient){}


  public getUserList(id: number, page: number, count: number, sort_column: string, sort_direction: string, filters=null): Observable<any> {

    let params = new HttpParams()
      .set('page', page.toString())
      .set('count', count.toString())
      .set('sort_column', sort_column)
      .set('sort_direction', sort_direction);
    if(filters){
      console.log(JSON.stringify(filters))
      params = params.set('filters', JSON.stringify(filters));

    }
    if(id != null)
      params = params.set('id', id.toString());

    return this._http.get<any>(this.userList, {headers: httpOptions.headers, params: params});
  }

  public getUserEmailList(page: number, count: number, sort_column: string, sort_direction: string, filter: string): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('count', count.toString())
      .set('sort_column', sort_column)
      .set('sort_direction', sort_direction)
      .set('filter', filter);

    return this._http.get<any>(this.userEmailList, {headers: httpOptions.headers, params: params});
  }

  public getUserLimit(id: number): Observable<any> {
    let params = new HttpParams();

    if(id != null)
      params = params.set('id', id.toString());

    return this._http.get<any>(this.userLimit, {headers: httpOptions.headers, params: params});
  }

  public editAdminStatus(data): Observable<any> {
    return this._http.post<any>(this.userUpdateAdminStatus, data, {headers: httpOptions.headers});
  }

  public editAccountStatus(data): Observable<any> {
    return this._http.post<any>(this.userUpdateAccountStatus, data, {headers: httpOptions.headers});
  }

  public addUser(data): Observable<any> {
    return this._http.post<any>(this.userAdd, data, {headers: httpOptions.headers});
  }

  public editUser(data): Observable<any> {
    return this._http.post<any>(this.userEdit, data, {headers: httpOptions.headers});
  }
}
