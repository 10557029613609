import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(private authService: AuthenticationService, private router: Router) {
    if(!authService.user.admin){
      this.router.navigate(['/crawl'])
    }
  }

  ngOnInit() {
  }

}
