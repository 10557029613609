import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public isLoading = false;

  constructor(public authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {}

  async signIn() {
    this.isLoading = true;
    this.authenticationService.signIn();
  }
}
