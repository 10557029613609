import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8'
  })
};

@Injectable()
export class CrawlServices {
  public campaignList = '/user/list';
  public reportAdd = '/report/add';
  public reportDownload = '/report/download';
  public reportProgress = '/report/progress';
  public reportPause = '/report/pause';
  constructor(private _http: HttpClient){}


  public getCrawlList(id:number ,page: number, count: number, sort_column: string, sort_direction: string, filters=null): Observable<any> {
    let params = new HttpParams()
      .set('id', id.toString())
      .set('page', page.toString())
      .set('count', count.toString())
      .set('sort_column', sort_column)
      .set('sort_direction', sort_direction);

    if(filters){
      console.log(JSON.stringify(filters))
      params = params.set('filters', JSON.stringify(filters));

    }

    return this._http.get<any>(this.campaignList, {headers: httpOptions.headers, params: params});
  }

  public createCrawl(data): Observable<any> {
    return this._http.post<any>(this.reportAdd, data, {headers: httpOptions.headers});
  }

  public getProgress(data): Observable<any> {
    return this._http.post<any>(this.reportProgress, data, {headers: httpOptions.headers});
  }

  public pauseReport(id:number){
    let params = new HttpParams()
      .set('id', id.toString())
    return this._http.get<any>(this.reportPause, {headers: httpOptions.headers, params: params});
  }
}
