import {NgModule} from '@angular/core';
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';

import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { ThumbTextDirective } from './directives/mat-slide-toggle/thumb-text.directive';
import {MatFileUploadModule} from "../libraries/mat-file-upload/mat-file-upload.component";

@NgModule({
    imports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatGridListModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatSliderModule,
        MatRadioModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatTooltipModule,
        MatExpansionModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatRippleModule,
        MatProgressBarModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatFileUploadModule
    ],
  exports: [
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatGridListModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatRadioModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatRippleModule,
    MatProgressBarModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatFileUploadModule,
    ThumbTextDirective
  ],
    declarations: [ThumbTextDirective]
})
export class MaterialModule { }
