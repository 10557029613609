import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserServices} from "../user.services";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  public form: FormGroup;
  public text_status = null;
  public form_status = true;

  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    private _formBuilder: FormBuilder,
    private _userServices: UserServices,
  ) {}

  ngOnInit() {
    this.form = this._formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  public onSubmit() {
    if(!this.form.valid) {
      this.form_status = false;
      this.text_status = 'All fields are required';
      return;
    }

    this._userServices.addUser(this.form.getRawValue()).subscribe(
      (data) => {
        this.form_status = true;
        this.text_status = 'User has been created';

        setTimeout(() => {this.dialogRef.close(true);}, 800);
      },
      (error) => {
        this.form_status = false;
        this.text_status = error.error.error;
      }
    );
  }

}
