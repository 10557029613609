import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MatFileUploadComponent),
  multi: true
};

@Component({
  selector: 'app-mat-file-upload',
  templateUrl: './mat-file-upload.component.html',
  styleUrls: ['./mat-file-upload.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class MatFileUploadComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private _files: any[] = [];

  @Input('accept') private _accept: string = '*/*';
  @Input('multiple') private _multiple: boolean = false;
  @Input('formControl') private _formControl: FormControl;
  @Input('itemTemplate') private _itemTemplate: TemplateRef<any>;
  @Input('uploadTemplate') private _uploadTemplate: TemplateRef<any>;
  @Input('preview') private _preview: boolean = false;
  @Input('maxSize') private _maxSize: number = (1024 * 1024);
  @Input('maxLength') private _maxLength: number = 30;
  @Input('chooseText') public _chooseText: string = 'Choose a file';

  @Output('onLoadEnd') private _onLoadEnd: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputFileUpload', {static: true}) inputFileUploadRef: ElementRef;


  constructor(
    private _sanitizer: DomSanitizer
  ) {}

  private getFilenameWithoutExtension(filename: string): string {
    const index_point = filename.lastIndexOf('.');

    if (index_point < 0) {
      return filename
    } else {
      return filename.substr(0, index_point);
    }
  }

  private humanFileSize(bytes: number): string {
    const exp = Math.log(bytes) / Math.log(1024) | 0;
    const result = (bytes / Math.pow(1024, exp)).toFixed(2);

    return result + ' ' + (exp == 0 ? 'bytes' : 'KMGTPEZY'[exp - 1]);
  }

  public openInputFileUpload(): void {
    this.inputFileUploadRef.nativeElement.click();
  }

  public onDelete(index) {
    this._files[index].actions.delete()
  }

  ngOnInit(): void {
    const _input = this.inputFileUploadRef.nativeElement;

    if (this._multiple) {
      _input.setAttribute('multiple', 'multiple');
    }

    _input.addEventListener('change', () => {
      if (!this._multiple) {
        this._files = [];
      }

      for (let file of _input.files) {

        const _index = this._files.push({
          name: file.name,
          display_name: this.getFilenameWithoutExtension(file.name),
          size: file.size,
          display_size: this.humanFileSize(file.size),
          type: file.type,
          progress: 0,
          error: false,
          content: null,
        });

        this._files[_index - 1].actions = {
          delete: (index) => {
            this._files.splice(index, 1);
            this._formControl.setValue(null);
            this.inputFileUploadRef.nativeElement.value = '';
          }
        };

        const _reader: FileReader = new FileReader();

        _reader.onprogress = (e) => {
          this._files[_index - 1].progress = (e.loaded * 100) / e.total;
        };

        _reader.onerror = () => {
          this._files[_index - 1].actions.delete();
        };

        _reader.onabort = () => {
          this._files[_index - 1].actions.delete();
        };

        _reader.onloadend = (e: any) => {
          const error = e.target.error;

          if (error != null) {
            this._formControl.setErrors({
              fileError: true
            });

            this._files[_index - 1].error = true;

          } else if (this._files[_index - 1].size > this._maxSize) {
            this._formControl.setErrors({
              maxSize: true
            });
            this._files[_index - 1].error = true;

          } else if (this._files[_index - 1].name.length > this._maxLength) {
            this._formControl.setErrors({
              maxLength: true
            });
            this._files[_index - 1].error = true;
          } else {
            this._files[_index - 1].error = false;

            this._files[_index - 1].progress = 100;
            this._files[_index - 1].content = e.target.result;
            this._formControl.setValue(this._files[_index - 1]);

            if (this._onLoadEnd) {
              this._onLoadEnd.emit(_index - 1);
            }
          }
        };

        _reader.readAsDataURL(file);
      }
    });
  }

  writeValue(obj: any): void {
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  ngOnDestroy(): void {
    this._files = [];
    this._preview = false;
    this.inputFileUploadRef.nativeElement.value = null;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [MatFileUploadComponent],
  exports: [MatFileUploadComponent]
})
export class MatFileUploadModule {
}
