import {OverlayContainer} from '@angular/cdk/overlay';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Theme, ThemeService} from './services/theme.service';
import {UsersElement} from './models/users-element';
import {AuthenticationService} from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public data: UsersElement | null;

    public theme: Theme;
    public events: string[] = [];
    public themes: Theme[] = [];

    constructor(
        private themeService: ThemeService,
        private overlayContainer: OverlayContainer,
        private authService: AuthenticationService,
        public router: Router,
        private route: ActivatedRoute
    ) {
        console.log(this.route.snapshot)
        this.route.queryParams.subscribe(params => {
            console.log(params)
            if(params['access_token']) {
                this.authService.login(params['access_token'])
            }
        })
        this.theme = this.themeService.getDefaultTheme();
        this.overlayContainerTheming(this.theme.value);


    }

    public ngOnInit() {
        this.themes = this.themeService.getThemes();
        this.themeService.getTheme().subscribe((next) => {
            this.theme = next;
        });
    }

    public toggleTheme(value: string) {
        this.overlayContainerTheming(value);
        this.themeService.setTheme(value);
    }

    private overlayContainerTheming(themeClassName: string) {
        const cl = this.overlayContainer.getContainerElement().classList;

        if (cl.length > 1) {
            this.overlayContainer.getContainerElement().classList.remove(cl.item(cl.length - 1));
        }

        this.overlayContainer.getContainerElement().classList.add(themeClassName);
    }

    logout(): void {
        this.authService.logout().subscribe(() => {
            this.router.navigate(['/login']);
        });
    }
}
