import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable()
export class AppServices
{
  public countriesList = '/country/list';
  public languagesList = '/language/list';
  public columnsList = '/column/list';

  constructor(private _http: HttpClient){}


  public getCountryList(): Observable<any> {
    return this._http.get<any>(this.countriesList, {headers: httpOptions.headers});
  }

  public getLanguageList(): Observable<any> {
    return this._http.get<any>(this.languagesList, {headers: httpOptions.headers});
  }

  public getColumnList(): Observable<any> {
    return this._http.get<any>(this.columnsList, {headers: httpOptions.headers});
  }
}
