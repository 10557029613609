import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {MatSnackBar} from '@angular/material';
import {Router} from "@angular/router";
import {catchError, tap} from 'rxjs/internal/operators';
import {BaseService} from './base.service';
import {UsersElement} from "../models/users-element";


@Injectable()
export class AuthenticationService extends BaseService {

  public isLogged = false;
  public error: string;
  public user: UsersElement;

  constructor(private http: HttpClient, public router: Router, public snackBar: MatSnackBar) {
    super(router, snackBar);
  }

  public signIn() {
    window.location.href = 'https://auth.iphub.me/login?scope=user.readbasic.all offline_access&redirect='
        + window.location.protocol + '//' + window.location.host
        + '/login';
  }

  public login(access_token) {
    this.http.post('/authentication/login', {access_token}).subscribe((response:any) => {
      if(response) {
        console.log(response);
        this.user = response;
        this.isLogged = true;

        this.router.navigate(['/crawl']);
      }
    })
  }

  public checkAuthentication() {
    return this.http.get('/authentication/check').pipe(
      catchError(this.snackHttpError),
      tap((response: any) => {
        if (response.success) {
          this.user = response.user;
          this.isLogged = true;
        } else {
          this.user = undefined;
          this.isLogged = false;
        }
      })
    );
  }

  public logout() {
    return this.http.get('/authentication/logout').pipe(
      catchError(this.snackHttpError),
      tap((response: any) => {
        if (response.success) {
          this.user = undefined;
          this.isLogged = false;
        }
      })
    );
  }
}
