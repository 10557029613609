import { NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../core/material.module';

import {CrawlServices} from "./crawl.services";
import {CrawlRoutingModule} from "./crawl-routing.module";
import {CrawlComponent} from "./crawl.component";
import { ListCrawlComponent } from './list-crawl/list-crawl.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CrawlRoutingModule,
        MaterialModule
    ],
    declarations: [
        CrawlComponent,
        ListCrawlComponent
    ],
    entryComponents: [],
    providers: [
      CrawlServices
    ]
})
export class CrawlModule { }
