import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {CrawlComponent} from "./crawl.component";
import {ListCrawlComponent} from "./list-crawl/list-crawl.component";
import {AuthGuardService} from "../../services/auth-guard.service";

const routes: Routes = [
  {
    path: 'crawl',
    component: CrawlComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: ListCrawlComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CrawlRoutingModule {
}
