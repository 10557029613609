import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {UserComponent} from "./user.component";
import {ListUserComponent} from "./list-user/list-user.component";
import {AuthGuardService} from "../../services/auth-guard.service";

const routes: Routes = [
  {
    path: 'users',
    component: UserComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: ListUserComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
}
