import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDrawer, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';

import {catchError, delay, map, startWith, switchMap} from "rxjs/operators";
import {BehaviorSubject, merge, Observable, of} from "rxjs";
import {CrawlServices} from "../crawl.services";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserServices} from "../../user/user.services";
import {AuthenticationService} from "../../../services/authentication.service";
import {AppServices} from "../../../services/app.services";
import {formatNumber} from "@angular/common";
import {MatFileUploadComponent} from "../../../libraries/mat-file-upload/mat-file-upload.component";

@Component({
  selector: 'app-list-crawl',
  templateUrl: './list-crawl.component.html',
  styleUrls: ['./list-crawl.component.scss']
})
export class ListCrawlComponent implements OnInit, AfterViewInit
{
  public loadingSubject = new BehaviorSubject<boolean>(true);
  public isLoadingResults$ = this.loadingSubject.asObservable();

  public resultsLength = 0;
  public slideColor = 'accent';
  public user;
  public hasData = false;
  public progress = {};

  public form_no_error:any = null;
  public form_error:any = false;

  public listCountries: Observable<string[]>;
  public listLanguages: Observable<string[]>;
  public columns: object[];

  public formCrawlers: FormGroup;

  public formFilter: FormGroup;

  public dataSource = new MatTableDataSource();
  public columnTemp: Array<any>;
  public columnsToDisplay = {
      created_time: 'Created Date',
      country: 'Country',
      language: 'Language',
      keywords_filename: 'Filename',
      count_kws: 'Count of keywords',
      status: 'Status',
      actions: '',
  };

  private _interval;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatDrawer, {static: false}) drawer: MatDrawer;

  @ViewChild('fileupload', {static: false}) fileUpload: MatFileUploadComponent;

  constructor(
    private _formBuilder: FormBuilder,
    private _crawlServices: CrawlServices,
    private _userServices: UserServices,
    private _authService: AuthenticationService,
    private _appService: AppServices
  ) { }

  ngOnInit() {
    this.columnTemp = Object.keys(this.columnsToDisplay);

    this.formFilter = this._formBuilder.group({
      filter: null,
      statusFilter: null
    });

    this.formFilter.valueChanges.subscribe(res => {

      this.loadData();
    });

    this.formCrawlers = this._formBuilder.group({
      keywords_file: [null, Validators.required],
      negative_keywords_file: [null],
      country: [null],
      language: [null],
      columns: [null],
      with_suggests: [false],
      include_partner_search_network: [false],
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.loadCountries();
    this.loadLanguages();
    this.loadColumns();
    this.loadLimit();


  }

  private loadData() {
    let filters = {};

    if(this.formFilter.get('filter').value != null){
      filters['filter'] = this.formFilter.get('filter').value;
    }
    if(this.formFilter.get('statusFilter').value != null){
      console.log(this.formFilter.get('statusFilter').value);
      filters['statusFilter'] = this.formFilter.get('statusFilter').value;
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          this.loadingSubject.next(true);
          return this._crawlServices.getCrawlList(this._authService.user.id,
            this.paginator.pageIndex+1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            filters
          );
        }),
        map((data:any) => {
          setTimeout(() => this.loadingSubject.next(false), 800);

          this.resultsLength = data.total;
          this.hasData = (data.total > 0) ?  true : false;

          return data.items;
        }),
        catchError(() => {
          setTimeout(() => this.loadingSubject.next(false), 800);

          return of([]);
        })
      )
      .subscribe(data => {
        this.dataSource.data = data;

        if(this._interval !== undefined)
        {
          clearInterval(this._interval);
        }

        this._interval = setInterval(() => this.getProgress(), 2000);
      });
  }


  private loadLimit() {
    this._userServices.getUserLimit(this._authService.user.id).subscribe(data => {
      this.user = data;
      this.loadData();
    });
  }

  public displayFn(data: any): string | undefined {
    let r;

    if(data)
    {
      if('name'in data)
      {
        r = data.name;
      }

      if('code' in data)
      {
        r = data.code.toUpperCase() +' - ' + r;
      }
    } else {
      r = data;
    }

    return r;
  }

  public start() {
    this._crawlServices.createCrawl(this.formCrawlers.getRawValue()).subscribe(
      (data) => {
        //this.form_no_error = 'Crawler has been load';
        this.drawer.toggle();
        this.loadData();
        this.formCrawlers = this._formBuilder.group({
          keywords_file: [null, Validators.required],
          negative_keywords_file: [null],
          country: [null],
          language: [null],
          columns: [null],
          with_suggests: [false],
          include_partner_search_network: [false],
        });
        this.fileUpload.ngOnDestroy();
        this.loadCountries();
        this.loadLanguages();
        this.loadColumns();
        this.loadLimit();
      },
      (error) => {
        this.form_error = error.error.error;
      });
  }

  public loadCountries() {
    this._appService.getCountryList().subscribe((data) => {
      this._filterEvent('listCountries', 'formCrawlers', 'country', data.items);
    })
  }

  public loadLanguages() {
    this._appService.getLanguageList().subscribe((data) => {
      this._filterEvent('listLanguages', 'formCrawlers', 'language', data.items);
    })
  }

  public loadColumns() {
    this._appService.getColumnList().subscribe((data) => {
      this.columns = data.items;
    })
  }

  public pauseReport(id){
    this._crawlServices.pauseReport(id).subscribe();
  }
  public download(data) {
    window.location.assign('/report/download?id=' + data.id);
  }

  public getProgress() {
    const ids = this.dataSource.data.map((x:any) => x.id);

    this._crawlServices.getProgress(ids).subscribe(data => {
      this.progress = data;
    });
  }

  public getFormatNumber(value) {
    return (value).toLocaleString('fr-FR', {minimumFractionDigits: 0});
  }

  private _filterEvent(variableGlobal, variableForm, keyForm, data) {
    this[variableGlobal] = this[variableForm].get(keyForm).valueChanges
      .pipe(
        startWith(null),
        map( (value:string) => typeof value === 'string' ? this._filter(data, value) : data),
      );
  }

  private _filter(data, name: string): any {
    const value = name.toLowerCase();
    return data.filter(option => option.name.toLowerCase().indexOf(value) === 0);
  }
}
